import { Text } from '@shared/components';
import { AuthorizeMicrosoftInstructions } from '@shared/components/authorizeInstructions/AuthorizeMicrosoftInstructions';

export const WEB_INTEGRATION_CONIFIGURATION_URL = `${
  import.meta.env.VITE_WEB_BASE_URL
}/integrations`;

export const AuthorizeMicrosoftNotice = () => {
  return (
    <div className="p-4 flex flex-col">
      <Text type="body-sm" className="text-gray-600 text-left">
        Please configure the Vooma add-in for Outlook integration.
      </Text>
      <AuthorizeMicrosoftInstructions
        webIntegrationsUrl={WEB_INTEGRATION_CONIFIGURATION_URL}
      />
    </div>
  );
};
