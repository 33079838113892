import { useLogoutMutation } from '@shared/generated/graphql';

export const useLogout = () => {
  const [logoutMutation, { loading, error }] = useLogoutMutation();

  const logout = async () => {
    await logoutMutation();
  };

  return { logout, loading, error };
};
