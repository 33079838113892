import {
  JobSource,
  JobType,
  useCreateJobFromOutlookMutation,
} from '@/generated/graphql';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { JobEmailMetadata } from '@shared/types/job';

interface CreateJobPops {
  jobType: JobType;
  restfulItemId: string;
  emailMetadata: JobEmailMetadata;
  isSharedInbox: boolean;
}

export const useCreateJobMutation = () => {
  const { me, defaultOrgId } = useMeContext();
  const [createJobsFromOutlook, { loading, error }] =
    useCreateJobFromOutlookMutation();

  const createJob = ({
    jobType,
    restfulItemId,
    emailMetadata,
    isSharedInbox,
  }: CreateJobPops) => {
    if (!defaultOrgId) throw new Error('No default org id');

    return createJobsFromOutlook({
      variables: {
        input: {
          organizationId: defaultOrgId,
          userId: me?.id,
          emailMetadata,
          isSharedInbox,
          outlookMessageData: {
            itemId: restfulItemId,
            // TODO(parlato): Remove this after we've fully deprecated messageId
            messageId: restfulItemId,
          },
          jobType,
          jobSource: JobSource.OUTLOOK,
        },
      },
      refetchQueries: ['GetJobs'],
    });
  };

  return {
    createJob,
    loading,
    error,
  };
};
