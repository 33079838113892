import { Text } from '@shared/components';
import { Dropdown } from '@shared/components/plugin-components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { useLogout } from '@shared/hooks/useLogout';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const { logout: logoutFromAuth0 } = useAuthContext();
  const { logout: logoutFromApi } = useLogout();
  const { hasQuotes } = useMeContext();

  const onLogout = () => {
    logoutFromAuth0();
    logoutFromApi();
  };

  const navigate = useNavigate();
  const getItems = () => {
    return [
      ...(hasQuotes
        ? [
            {
              node: (
                <div
                  className="py-2"
                  onClick={() => {
                    navigate(QUOTES_ROUTE);
                  }}
                >
                  <Text type="body-xs">Quotes</Text>
                </div>
              ),
            },
          ]
        : []),
      {
        node: (
          <div
            className="py-2"
            onClick={() => {
              navigate('/settings');
            }}
          >
            <Text type="body-xs">Account settings</Text>
          </div>
        ),
      },
      {
        node: (
          <div onClick={onLogout} className="py-2">
            <Text type="body-xs">Log out</Text>
          </div>
        ),
      },
    ];
  };

  return <Dropdown items={getItems()} />;
};
export default Menu;
