import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

/* global Office */

const container = document.getElementById('root') as HTMLElement;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  enabled: true,
  enableTracing: true,
  environment: import.meta.env.VITE_ENV,
});

Office.onReady(() => {
  ReactDOM.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>,
    container
  );
});
