import { Button } from '@shared/components';
import { Menu } from '@shared/plugin/features/Menu';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { motion } from 'framer-motion';
import { GoArrowLeft } from 'react-icons/go';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
interface LayoutProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  navigateBackTo?: 'quote-page';
  webBaseUrl: string;
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.6,
    },
  },
};

export const Layout = ({
  hasMenu,
  children,
  navigateBackTo,
  webBaseUrl,
}: LayoutProps) => {
  const navigate = useNavigate();
  const { quoteId } = useParams();

  function navigateBackHandler() {
    if (!navigateBackTo) {
      navigate(-1);
      return;
    }
    switch (navigateBackTo) {
      case 'quote-page':
        if (quoteId) {
          navigate(`${QUOTES_ROUTE}/${quoteId}`);
        }
    }
  }
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="flex flex-col text-body-md overflow-hidden h-screen"
    >
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="flex flex-row justify-between p-2 text-gray-700 bg-white items-center z-10">
          {hasMenu ? (
            <Menu />
          ) : (
            <Button
              onPress={navigateBackHandler}
              variant="secondary"
              size="xs"
              icon={<GoArrowLeft size={12} />}
            >
              Back
            </Button>
          )}

          <a href={webBaseUrl} target="_blank" rel="noreferrer">
            <Button
              size="xs"
              icon={<MdOutlineOpenInNew className="text-brand-500" />}
              tooltip="Open Vooma web app"
              variant="secondary"
              iconPosition="right"
            >
              Vooma
            </Button>
          </a>
        </div>
        <div className="flex flex-col flex-1 bg-gray-50 overflow-y-hidden">
          {children}
        </div>
      </div>
    </motion.div>
  );
};
