import { useCreateJobMutation } from '@/hooks/useCreateJobMutation';
import { useReplyAll } from '@/hooks/useReplyAll';
import { EmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { parseNotifications } from '@shared/features/automatic-navigator/AutomaticNavigator';
import { JobType } from '@shared/generated/graphql';
import { JobEmailMetadata } from '@shared/types/job';
import { isNil } from 'lodash';
import { useOutlookAdaptor } from './useOutlookAdaptor';
import { getRestfulId } from './utils/getRestfulId';

function hasMessageItem(item?: Office.MessageRead): item is Office.MessageRead {
  if (isNil(item)) return false;
  return item.itemType === Office.MailboxEnums.ItemType.Message;
}

function getEmailMetadata(
  item: Office.MessageRead,
  isSharedInbox: boolean,
  sharedInboxOwner?: string
): JobEmailMetadata {
  const { emailAddress } = Office.context.mailbox.userProfile;
  let replyTo = emailAddress;
  if (isSharedInbox && sharedInboxOwner) {
    replyTo = sharedInboxOwner;
  }
  return {
    sender: emailAddress,
    replyTo,
    subject: item.subject,
    messageId: item.internetMessageId,
    itemId: getRestfulId(item),
    originatorAddress: item.sender.emailAddress,
  };
}

type OutlookThreadContextProviderProps = {
  children: JSX.Element;
};
export const OutlookThreadContextProvider = ({
  children,
}: OutlookThreadContextProviderProps) => {
  const createOrderJob = useCreateJobMutation();
  const createQuoteJob = useCreateJobMutation();
  const reply = useReplyAll();
  const {
    item,
    itemBody,
    isSharedInbox,
    sharedInboxOwner,
    sharedInboxCandidates,
    selectSharedInboxCandidate,
    jobs,
    jobsLoading,
    jobTypes,
    storeJobType,
  } = useOutlookAdaptor();

  const createOrderFromThread = async () => {
    if (hasMessageItem(item)) {
      const res = await createOrderJob.createJob({
        restfulItemId: getRestfulId(item),
        isSharedInbox,
        emailMetadata: getEmailMetadata(item, isSharedInbox, sharedInboxOwner),
        jobType: JobType.ORDERS,
      });
      return res.data?.createOutlookJob.job;
    }
  };

  const createQuoteFromThread = async () => {
    if (hasMessageItem(item)) {
      const res = await createQuoteJob.createJob({
        restfulItemId: getRestfulId(item),
        isSharedInbox,
        emailMetadata: getEmailMetadata(item, isSharedInbox, sharedInboxOwner),
        jobType: JobType.QUOTES,
      });
      return res.data?.createOutlookJob.job;
    }
  };

  const thisItem = item && {
    id: item.itemId,
    sender: {
      emailAddress: item.sender.emailAddress,
    },
  };

  const sharedInbox = {
    isSharedInbox,
    owner: sharedInboxOwner,
    candidates: sharedInboxCandidates,
    selectCandidate: selectSharedInboxCandidate,
  };

  const threadJobs = {
    jobs,
    jobTypes,
    storeJobType,
    loading: jobsLoading,
  };

  const createOrder = {
    call: createOrderFromThread,
    error: createOrderJob.error,
    loading: createOrderJob.loading,
  };

  const createQuote = {
    call: createQuoteFromThread,
    error: createQuoteJob.error,
    loading: createQuoteJob.loading,
  };

  const openThread = (emailMetadata: JobEmailMetadata) => {
    if (emailMetadata.itemId) {
      Office.context.mailbox.displayMessageForm(emailMetadata.itemId);
    }
  };

  return (
    <EmailThreadContext.Provider
      value={{
        thisItem,
        notifications: parseNotifications(itemBody),
        threadJobs,
        createOrder,
        createQuote,
        sharedInbox,
        reply,
        openThread,
      }}
    >
      {children}
    </EmailThreadContext.Provider>
  );
};
