import { isHostedInWeb } from '@/utils/hostingApp';
import Login from '@shared/features/login/Login';

const login = async (email: string, password: string) => {
  return await fetch(`${import.meta.env.VITE_API_BASE_URL}/auth0/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
};

const OutlookLogin = () => {
  return (
    <Login
      loginFn={login}
      displayLogo={isHostedInWeb(Office.context.mailbox.diagnostics.hostName)}
    />
  );
};

export default OutlookLogin;
