import { uniq } from 'lodash';

export const getCurrentMessage = (): Office.MessageRead | undefined => {
  const { item } = Office.context.mailbox;

  if (item?.itemType === Office.MailboxEnums.ItemType.Message) {
    return item;
  }
};

export const getItemParticipants = (): {
  participants: string[];
} => {
  const { item } = Office.context.mailbox;

  if (
    item?.to?.length &&
    item?.itemType === Office.MailboxEnums.ItemType.Message
  ) {
    const toRecipients = item.to.map((recipient) => recipient.emailAddress);
    const ccRecipients =
      item.cc?.map((recipient) => recipient.emailAddress) || [];
    const from = item.from.emailAddress;
    // NOTE(parlato): from and sender are the same in most cases, but differ
    // when the message is sent by a delegate
    const sender = item.sender.emailAddress;

    // TODO(parlato): Need to handle BCC as well
    const participants = uniq([...toRecipients, ...ccRecipients, from, sender]);
    return { participants };
  }

  return { participants: [] };
};

export const getConversationId = () => {
  const currentMessage = getCurrentMessage();
  if (currentMessage) {
    return Office.context.mailbox.convertToRestId(
      currentMessage.conversationId,
      Office.MailboxEnums.RestVersion.v2_0
    );
  }

  return;
};
